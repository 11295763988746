import React from 'react';
import Heading from 'src/components/Heading';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import WysiwygWrapper from 'src/components/WysiwygWrapper';

const StyledHeading = styled(Heading)`
    font-family: Tiempos;
    font-style: normal;
    font-weight: normal;

    ${theme.fontSize.serif.mobile._24};
    ${theme.fontSize.serif.tablet._24};
    ${theme.fontSize.serif.desktop._24};

    ${theme.spacing.mobile._12('margin-bottom')};
    ${theme.spacing.tablet._12('margin-bottom')};
    ${theme.spacing.desktop._12('margin-bottom')};
`;

const Wrapper = styled('div')`
    font-family: Tiempos;
    font-style: normal;
    font-weight: normal;
    text-align: left;

    ${theme.spacing.mobile._12('margin-left')};
    ${theme.spacing.tablet._0('margin-left')};
    ${theme.spacing.desktop._0('margin-left')};

    ${theme.spacing.mobile._12('margin-right')};
    ${theme.spacing.tablet._0('margin-right')};
    ${theme.spacing.desktop._0('margin-right')};

    ${theme.spacing.mobile._40('margin-bottom')};
    ${theme.spacing.tablet._40('margin-bottom')};
    ${theme.spacing.desktop._40('margin-bottom')};

    ${theme.fontSize.sans.mobile._16};
    ${theme.fontSize.sans.tablet._16};
    ${theme.fontSize.sans.desktop._16};

    & a {
        font-family: Tiempos;
    }
`;

const StyledText = styled(Text)`
    font-family: Tiempos;
    line-height: 1.62em;
`;

const TextSection = props => {
    return (
        <Wrapper>
            <StyledHeading>{props.data.postTextSection.heading}</StyledHeading>
            <WysiwygWrapper textComponent={StyledText} data={props.data.postTextSection.text} />
        </Wrapper>
    );
};

export default TextSection;

TextSection.propTypes = {
    data: PropTypes.shape({
        module: PropTypes.string,
        postTextSection: PropTypes.shape({
            heading: PropTypes.string,
            text: PropTypes.array,
        }),
    }),
};

TextSection.defaultProps = {
    data: {
        module: '',
        postTextSection: {
            heading: '',
            text: [],
        },
    },
};
